import React from "react"
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import SoftwareDownloadButton from "../../../../components/SoftwareDownload/SoftwareDownloadButton";
import {LocaleContext} from "../../../../components/layout";

const Barometer = () => {
    const { locale } = React.useContext(LocaleContext)
    const BarometerJa = () => {
        return(
            <div className="content-container">
                <div className="software-content">
                    <h2 className={'large bold'}>デジタル気圧計 SK-500B用 ソフトウェアダウンロード</h2>

                    <div className="terms-of-service-container">
                        <div className="content-pre">
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                            <p>このドキュメントは本製品に関する重要な情報について書かれています。<br/>
                                ご使用の前に、必ずご一読くださるようお願いいたします。</p>
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        </div>
                        <div className="content-title">
                            <p>＜利用規約＞</p>
                        </div>
                        <div className="content-disclaimer">
                            <div className="content-disclaimer-title">
                                <p> ■免責事項</p>
                            </div>
                            <div className="content-disclaimer-content">
                                <p>
                                    株式会社佐藤計量器製作所は本製品に関して動作確認はおこなっておりますが、全ての状況下において動作を保証しているわけではありません。<br/>
                                    本製品により生じた損害は、直接的および間接的損害を問わず、弊社に一切の賠償責任はないものとします。<br/>
                                    操作上のミス、注意を無視した操作、ご使用になられているＰＣに起因する不具合に対して、弊社は責任を負いかねますのでご了承ください。<br/><br/>
                                    ※こちらで提供いたしております各種ソフトウェアは、日本国内で販売されている各製品と日本語版OSの組み合わせのみ、動作確認をおこなっております。<br/>
                                    海外版OSとの組み合わせにて発生したトラブルに関しましては、一切保証およびサポートいたしかねますのでご了承ください。
                                </p>
                            </div>
                        </div>
                        <div className="content-copyright">
                            <div className="content-copyright-title">
                                <p> ■著作権</p>
                            </div>
                            <div className="content-copyright-content">
                                <ol>
                                    <li>
                                        「SK-500B for Windows」のプログラムおよび関連ドキュメントに関する著作権は株式会社佐藤計量器製作所に帰属します。
                                    </li><br/>
                                    <li>
                                        「SK-500B for Windows」は以下条件をもとに、無償でご利用いただけます。
                                        <ul>
                                            <li>
                                                <p>逆コンパイル・ファイル解析、改変等を禁じます。</p>
                                            </li>
                                            <li>
                                                <p>本プログラムの使用による損害は、弊社は一切の責任を負いません。</p>
                                            </li>
                                            <li>
                                                <p>再配布は自由としますが、企業内・企業間、その他関係においても営利目的にしないこと。<br/>
                                                    再配布したプログラムの使用による損害に対しても弊社は一切の責任を負いません。</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                                <p>本利用規約に同意いただけた方にのみ、以下ソフトウェアをご利用いただけます。</p>
                            </div>
                        </div>
                        <div className="content-feature">
                            <div className="content-feature-title">
                                <p> ■ソフトウェアの特徴</p>
                            </div>
                            <div className="content-feature-content">
                                <ol>
                                    <li>
                                        <p>SK-500B for Windows</p>
                                        <p>「SK-500B for Windows」は、デジタル気圧計「ＳＫ－５００Ｂ」のＲＳ－２３２Ｃ出力を読み込み、グラフ表示やデータの保存をおこなうものです。 データはＣＳＶ形式ファイルとして保存でき、市販の表計算ソフト等でデータ解析が可能です。</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="way-to-install">
                        <div className="install-title">
                            <p className="bold">【インストール方法】</p>
                        </div>
                        <div className="install-content">
                            <p>
                                「<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/500b_installmanual-W05.pdf" target={"_blank"} rel="noreferrer">デジタル気圧計用ソフトウェアインストールマニュアル.pdf</a>」に従ってインストールをおこなってください。
                            </p>
                            <p className="important-text">(インストール前に必ずお読みください。)</p>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">【ダウンロード】</p>
                        </div>
                        <div className="download-pre">
                            <p>※お使いのパソコンのOSバージョンおよびbit数をご確認の上、対応するソフトウェアをダウンロードしてください。</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">ソフトウェア名</p>
                                    </th>
                                    <td>
                                        <p>SK-500B for Windows Ver.1.1J</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">対象機器</p>
                                    </th>
                                    <td>
                                        <p>SK-500B</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">対象OS</p>
                                    </th>
                                    <td>
                                        <p>
                                            Windows11<br/>
                                            Windows10 （64bit版/32bit版）<br/>
                                            Windows8.1（64bit版/32bit版）<br/>
                                            Windows8　（64bit版/32bit版）<br/>
                                            Windows7　（64bit版/32bit版）<br/>
                                            WindowsXP （SP3以上）
                                        </p>
                                        <p>
                                            ※WindowsXP、7、8、8.1について<br/>
                                            　上記OSはMicrosoft社のOSサポートが終了しています。<br/>
                                            　ソフトウェアは引き続きご利用いただけますが、上記OS上での動作や不具合などについての<br/>
                                            　お問い合わせには回答いたしかねますのでご了承ください。
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">ダウンロード</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey="corporate/software/500B11J.zip"
                                            tmpKey="software/500B11J.zip"
                                        />
                                        <p>※ご使用のウェブブラウザーによっては、ダウンロードが正しくおこなわれない場合があります。<br/>
                                        　その場合は下記をご参照の上、ダウンロードをおこなってください。<br/>
                                        　　Microsoft Edge：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Edgeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Edgeご使用時の補足.pdf」</a><br/>
                                        　　Google Chrome ：<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/Chromeご使用時の補足.pdf" target={"_blank"} rel="noreferrer">「Chromeご使用時の補足.pdf」</a>
                                        </p>                        
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">バージョンアップ内容</p>
                                    </th>
                                    <td>
                                        <p>Windows 8、Windows 8.1、Windows 10 に対応しました。</p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const BarometerEn = () => {
        return(
            <div className="content-container">
                <div className="software-content">
                    <h2 className={'large bold'}>Software for Digital Barometer Model SK-500B</h2>
                    <div className="terms-of-service-container">
                        <div className="content-pre">
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                            <p>This document describes important information about this product.<br/>
                                Read it thoroughly before use.</p>
                            <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                        </div>
                        <div className="content-disclaimer">
                            <div className="content-disclaimer-title">
                                <p className="bold">Software Use Agreements</p>
                            </div>
                            <div className="content-disclaimer-content">
                                <p>
                                    This software shall be used only by users who have agreed on the following terms:
                                </p>
                                <p>
                                    Exemption from liability<br/>
                                    We, Sato Keiryoki Mfg. Co., Ltd., check for general operation performance of the program, however we do not guarantee for those under all possible situations.<br/>
                                    We do not take any responsibilities on any damages caused directly or indirectly by this program.<br/>
                                </p>
                                <p>
                                    NB. The operation of our software has been checked by a combination of the Japanese edition OS and the device sold in Japan. We shall not guarantee nor support the problems that occurred during the usage of device and/or OS of overseas model (edition).
                                </p>
                            </div>
                        </div>
                        <div className="content-copyright">
                            <div className="content-copyright-title">
                                <p className="bold">Copyright</p>
                            </div>
                            <div className="content-copyright-content">
                                <ol>
                                    <li>
                                        The copyright of the program "SK-500B for Windows" and related documents belongs to Sato Keiryoki Mfg. Co., Ltd.
                                    </li><br/>
                                    <li>
                                        The program "SK-500B for Windows" can be used at free of charge under the following conditions:
                                        <ul>
                                            <li>
                                                <p>Any decompilation, file analysis or alteration is prohibited.</p>
                                            </li>
                                            <li>
                                                <p>We shall not be responsible for any damage resulting from the use of this program.</p>
                                            </li>
                                            <li>
                                                <p>The responsibilities related to redistribution of the program are entirely onto users; in case of redistribution, the program shall not be used for commercial reasons even when used in-corporation, inter-corporation or by others.<br/>
                                                    We shall not be responsible for any damage resulting from the use of the redistributed program.</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div className="content-feature">
                            <div className="content-feature-title">
                                <p className="bold">Features</p>
                            </div>
                            <div className="content-feature-content">
                                <ol>
                                    <li>
                                        <p>SK-500B for Windows</p>
                                        <p>"SK-500B for Windows" loads the signals of RS-232C output from the Digital Barometer SK-500B. The acquired data are evaluated with graph plotting and saved in CSV format. Furthermore, the saved data can be analyzed by using commercial spreadsheet software supporting the "CSV" extension.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="way-to-install">
                        <div className="install-title">
                            <p className="bold">Installation Method</p>
                        </div>
                        <div className="install-content">
                            <p>
                                Install "SK-500B for Windows" following "<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/SK-500B_Software_installation_manual_V11E.pdf" target={"_blank"} rel="noreferrer">Software Installation Manual.pdf</a>".
                            </p>
                            <p className="important-text">(Be sure to read before installation)</p>
                        </div>
                    </div>
                    <div className="download">
                        <div className="download-title">
                            <p className="bold">Software Download</p>
                        </div>
                        <div className="download-pre">
                            <p>* Be sure to check the OS version and the bit number of your computer before starting the installation and download the applicable software.</p>
                        </div>
                        <div className="download-content">
                            <table>
                                <tbody>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Program Name</p>
                                    </th>
                                    <td>
                                        <p>SK-500B for Windows Ver.1.1E</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Model</p>
                                    </th>
                                    <td>
                                        <p>SK-500B</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">OS</p>
                                    </th>
                                    <td>
                                        <p>
                                            Windows XP （SP3）
                                            <br/>
                                            Windows 7  （64/32bit）
                                            <br/>
                                            Windows 8  （64/32bit）
                                            <br/>
                                            Windows 8.1（64/32bit）
                                            <br/>
                                            Windows 10 （64/32bit）
                                        </p>
                                        <p>
                                            * Operation verification has been done by Windows 7, Windows 8, Windows 8.1, Windows 10 Professional 64-bit and 32-bit editions.
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        <p className="download-table-title">Download</p>
                                    </th>
                                    <td>
                                        <SoftwareDownloadButton
                                            yourKey="corporate/software/500B11E.zip"
                                            tmpKey="software/500B11E.zip"
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    {(locale === 'ja')?
                        <BarometerJa/>:
                        <BarometerEn/>
                    }
                </section>
            </article>
            <Footer/>
        </>
    )
}

export default Barometer
